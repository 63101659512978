<!--
File: PlanEditForm.vue
Description: form for adding/editing plan, called from Plans.vue
-->
<template>
  <form>
    <md-card>
      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-small-size-100 md-size-100">
            <RegionsDropdown :class="[{ 'md-valid': !errors.has('fk_region') }, { 'md-error': errors.has('fk_region') }]"
              :label="$t('road_network.region')" v-model="fk_region" data-vv-name="fk_region" :initial_value="fk_region"
              :is_required="false" v-validate="modelValidations.fk_region" />

            <md-field :class="[{ 'md-valid': !errors.has('year') }, { 'md-error': errors.has('year') }]">
              <label>{{ $t('budget.year') }}</label>
              <md-input v-model="year" type="number" data-vv-name="year" required v-validate="modelValidations.year"
                min="2021" />
            </md-field>

            <md-field :class="[{ 'md-valid': !errors.has('name') }, { 'md-error': errors.has('name') }]">
              <label>{{ $t('stdCols.name') }}</label>
              <md-input v-model="name" type="text" data-vv-name="name" required v-validate="modelValidations.name"
                :placeholder="$t('label.enter_name')" />
            </md-field>
          </div>
        </div>
        <span v-if="errors.count() > 0" class="md-error">{{ $t('messages.errors_count', { count: errors.count() })
        }}</span>
        <div class="md-layout-item md-size-100 text-right">
          <md-button class="md-success" native-type="submit" @click.native.prevent="validate" :disabled="saveBtnDisabled">
            {{ $t('buttons.save') }}
          </md-button>
          <md-button class="md-accent" @click.stop.prevent="onModalClose">
            {{ $t('buttons.close') }}
          </md-button>
        </div>
      </md-card-content>
    </md-card>
  </form>
</template>
<script>
  import { mapGetters } from 'vuex'
  import RegionsDropdown from '@/pages//Dropdowns/RegionsDropdown.vue'
  import messagesMixin from '@/mixins/messagesMixin'
  import { onModalClose } from '@/mixins/onCloseMixin'

  export default {
    name: 'edit-profile-form',
    mixins: [messagesMixin],

    props: {
      plan: { default: {}, type: Object },
    },

    data() {
      return {
        fk_region: 0,
        year: new Date().getFullYear(),
        name: '',

        modelValidations: {
          fk_region: { required: true, numeric: true, min_value: 1 },
          year: { required: true, numeric: true, min_value: 2021 },
          name: { required: true, min: 3 }
        }
      }
    },

    components: {
      RegionsDropdown
    },

    mounted() {
      if (this.plan) {
        this.fk_region = this.plan.fk_region
        this.year = this.plan.year
        this.name = this.plan.name
      }
    },

    methods: {
      onModalClose,

      async validate() {
        const isValid = await this.$validator.validateAll();
        if (!isValid) return
        const planToSave = {
          fk_region: this.fk_region,
          year: Number(this.year),
          name: this.name
        }

        const action = `${this.plan ? 'UPD' : 'ADD'}_PLAN`
        const reqData = this.plan ? { id: this.plan.id, plan: planToSave } : planToSave
        let errDesc = ''
        this.$store.dispatch(action, reqData).then(
          () => {
            this.$emit('saved')
          },
          (err) => {
            errDesc = err.message
          }
        )
        this.savedMessage(errDesc, this.$t('messages.plan_change'), this.name)
      }
    },

    watch: {
    },

    computed: {
      ...mapGetters(['planListByYear']),

      saveBtnDisabled() {
        return this.errors.items.length > 0
      },
    }
  }
</script>
<style>
.md-button+.md-button {
  margin-left: 10px;
}
</style>
